import { isProcessScanTask } from "@custom-types/sdb-background-tasks-type-guards";
import { UploadedData } from "@pages/project-details/project-data-management/uploaded-data/uploaded-data-types";
import { createSelector } from "@reduxjs/toolkit";
import { CaptureTreePointCloudType} from "@faro-lotv/service-wires";
import {
  captureTreeEntityClusterPathSelector,
  captureTreeSelector,
  isCaptureTreeScanEntityProcessingSelector,
  hasCaptureTreeScanEntityTaskErrorsSelector,
  allCaptureTreeRevisionsSelector,
} from "@store/capture-tree/capture-tree-selectors";
import { sdbBackgroundTasksSelector } from "@store/sdb-background-tasks/sdb-background-tasks-selector";
import { RootState } from "@store/store-helper";
import { isScanEntity } from "@utils/capture-tree-utils";
import { ProcessElsRawScanTask, ProcessPointCloudFlsRawTask } from "@custom-types/sdb-background-tasks-types";

/**
 * @returns All UploadedData entities.
 */
export const uploadedDataSelector: (state: RootState) => UploadedData[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const entities = captureTreeSelector(state);
      const scanEntities = entities.filter(isScanEntity);

      return scanEntities.map((entity) => {
        return {
          ...entity,
          clusterPath: captureTreeEntityClusterPathSelector(entity.id)(state),
          isProcessing: isCaptureTreeScanEntityProcessingSelector(entity)(state),
          hasTaskErrors: hasCaptureTreeScanEntityTaskErrorsSelector(entity)(state),
        };
      });
    }
  );

/**
 * @returns All tasks that added scans to the project, sorted from newest creation date to oldest.
 */
export const processScanTasksSelector: (state: RootState) => (ProcessElsRawScanTask | ProcessPointCloudFlsRawTask)[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const revisions = allCaptureTreeRevisionsSelector(state);
      const backgroundTasks = sdbBackgroundTasksSelector(state);
      const allProcessScanTasks = backgroundTasks.filter(isProcessScanTask);

      const processScanTasksOfProject = allProcessScanTasks.filter((processScanTask) => {
        return revisions.find((revision) => processScanTask.context?.projectId === revision.projectId);
      });

      return processScanTasksOfProject
        .sort((a, b) => {
          const aCreatedAt = new Date(a.createdAt).getTime();
          const bCreatedAt = new Date(b.createdAt).getTime();
          return bCreatedAt - aCreatedAt;
        });
    }
  );

/**
 * @returns true if there is any UploadedData entity getting processed.
 */
export const isProcessingSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const entities = uploadedDataSelector(state);
      return entities.some((entity) => entity.isProcessing);
    }
  );

/**
 * @returns true if all UploadedData entities, at least one, have been processed.
 */
export const isProcessedSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const entities = uploadedDataSelector(state);
      // isProcessing also returns false if processing hasn't started yet. So also check the desired result.
      // Some scans may have to be processed from e.g. ElsRaw to E57 and some may already be in E57.
      return 0 < entities.length && entities.every((entity) =>
        !entity.isProcessing && entity.pointClouds?.some((pc) => pc.type === CaptureTreePointCloudType.e57)
      );
    }
  );

/**
 * @returns true if there is any UploadedData entity with an error.
 */
export const hasProcessErrorSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const entities = uploadedDataSelector(state);
      return entities.some((entity) => entity.hasTaskErrors);
    }
  );
