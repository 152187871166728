import { useCoreApiClient } from "@api/use-core-api-client";
import { FaroButtonContained } from "@components/common/faro-button-contained";
import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { PageSection, PageSectionProps } from "@components/page/page-section";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { BaseMemberProps } from "@custom-types/member-types";
import { useToast } from "@hooks/use-toast";
import { Box, Grid, Skeleton } from "@mui/material";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { runtimeConfig } from "@src/runtime-config";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { loggedInUserSelector } from "@store/user/user-selector";
import { updateCurrentUserProfile } from "@store/user/user-slice";
import { ButtonEvents, UserProfile } from "@utils/track-event/track-event-list";
import { formatUserRoleType } from "@utils/data-display";
import {
  getIdentityProviderDisplayName,
  getMemberNameSplit,
} from "@utils/user-utils";
import { useMemo } from "react";
import { ChangeEmailDialog } from "@components/user-info/change-email-dialog";
import { MemberTabs } from "@router/route-params";
import {
  generateMemberProfileRoute,
  useAppParams,
} from "@router/router-helper";
import { withEllipsis } from "@styles/common-styles";
import { ChangePassword } from "@components/common/change-password/change-password";
import { useMediaQueryList } from "@hooks/use-media-query";
import { MemberAccountProfilePicture } from "@pages/profile/member-account-profile-picture";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { PartialNull } from "@custom-types/types";
import { PreservingNavigate } from "@components/common/preserve-navigation/preserving-navigate";
import { useShowCookieManager, useIsCookieManagerInitialized } from "@faro-lotv/foreign-observers";

interface Props extends PartialNull<BaseMemberProps> {
  /** Current user */
  currentUser: SphereDashboardAPITypes.ICompanyMemberBase | null;

  /** Flag to indicate if the content of the member is loading */
  isLoading: boolean;
}

/**
 * Content in the member account & security page
 */
export function MemberAccountSecurityPage({
  member,
  currentUser,
  isLoading,
}: Props): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const { showToast } = useToast();
  const { handleErrorWithToast } = useErrorContext();
  const { isScreenXsAndSmall } = useMediaQueryList();
  const loggedInUser = useAppSelector(loggedInUserSelector);
  const { companyId } = useAppParams();
  const { trackEvent } = useTrackEvent();
  const isCookieManagerInitialized = useIsCookieManagerInitialized();
  const showCookieManager = useShowCookieManager();

  const { firstName, lastName } = useMemo(() => {
    return getMemberNameSplit({ member });
  }, [member]);

  // If the user is trying to access the account page of another user, redirect to the overview page.
  // This could happen if user saved in bookmarks the account for it's user and then logged in with another user.
  if (currentUser && member && member.identity !== currentUser.identity) {
    if (!companyId || !member?.identity) {
      return <PreservingNavigate to="/" />;
    }
    return (
      <PreservingNavigate
        to={generateMemberProfileRoute(
          companyId,
          member.identity,
          MemberTabs.overview
        )}
      />
    );
  }

  /** Notifies the user that there was an error opening the cookie manager */
  function showManageCookiesError(): void {
    showToast({
      type: "error",
      message: "Unknown error loading cookie manager.",
      description: "Please reload the page or try again later.",
    });
  }

  /**
   * Shows the cookie manager for the user and tracks the event.
   */
  function manageCookies(): void {
    trackEvent({
      name: ButtonEvents.manageCookies,
    });

    // If the cookie manager is not available, show an error message
    if (!isCookieManagerInitialized) {
      showManageCookiesError();
      return;
    }

    try {
      showCookieManager();
    } catch (error) {
      showManageCookiesError();
    }
  }

  async function onChangeDisplayName(
    value: string,
    field: "firstName" | "lastName"
  ): Promise<string> {
    if (!currentUser) {
      return field === "firstName" ? firstName : lastName;
    }
    const fieldName = field === "firstName" ? "first name" : "last name";
    try {
      trackEvent({
        name: UserProfile.updateProfile,
        props: { attribute: fieldName },
      });
      await dispatch(
        updateCurrentUserProfile({
          coreApiClient,
          user: {
            ...currentUser,
            // Make sure to trim the value to avoid empty spaces
            [field]: value.trim(),
          },
        })
      );
    } catch (error) {
      handleErrorWithToast({
        id: `changeDisplayName-${Date.now().toString()}`,
        title: `Could not change ${fieldName}`,
        error,
      });
      return field === "firstName" ? firstName : lastName;
    }
    return value;
  }

  const pageSectionsProps: PageSectionProps[] = [
    {
      title: "Public Profile",
      subtitle:
        "Your profile photo and name appear when you log in to Sphere XG, " +
        "FARO products and services. You can update your profile at any time and it will automatically " +
        "update anywhere your profile photo and name appear.",
      items: [
        {
          label: "Profile Picture",
          content: (
            <Box
              component="div"
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {member && <MemberAccountProfilePicture member={member} />}
            </Box>
          ),
          shouldAddRightPadding: true,
          customSkeleton: (
            <Skeleton
              variant="circular"
              sx={{ width: "54px", height: "54px" }}
            />
          ),
        },
        {
          label: "First name",
          content: (
            <Box component="div" width="100%">
              <FaroTextField
                initialValue={firstName}
                onConfirmed={(value) => onChangeDisplayName(value, "firstName")}
                isFullWidth={true}
                faroVariant="row"
                isReadOnly={false}
                minInputLength={1}
              />
            </Box>
          ),
        },
        {
          label: "Last name",
          content: (
            <Box component="div" width="100%">
              <FaroTextField
                initialValue={lastName}
                onConfirmed={(value) => onChangeDisplayName(value, "lastName")}
                isFullWidth={true}
                faroVariant="row"
                isReadOnly={false}
                minInputLength={1}
              />
            </Box>
          ),
        },
        {
          label: "Role",
          content: (
            <div>{member?.role && formatUserRoleType(member?.role)}</div>
          ),
        },
      ],
    },
    {
      title: "Log In",
      subtitle:
        "You are using email and a password to log in. " +
        "Changing this information changes how you’ll sign in to FARO Sphere XG.",
      isHidden:
        !loggedInUser ||
        loggedInUser?.providerId !== CoreAPITypes.EUserJsonProviderId.email,
      items: [
        {
          label: "Email",
          content: <ChangeEmailDialog currentUser={currentUser} />,
        },
        {
          label: "Current Password",
          content: (
            <Box
              component="div"
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                component="div"
                sx={{
                  ...withEllipsis,
                  pr: "27px",
                }}
              >
                <var>●●●●●●●●●●●●●●</var>
              </Box>
              <ChangePassword email={currentUser?.email} />
            </Box>
          ),
          shouldAddRightPadding: true,
        },
      ],
    },
    {
      title: "Single sign-on (SSO)",
      subtitle:
        "You are using Single Sign On (SSO) to connect with Sphere XG. " +
        "The SSO provider cannot be changed automatically, if you need to change it, please contact us.",
      isHidden:
        !loggedInUser ||
        loggedInUser?.providerId === CoreAPITypes.EUserJsonProviderId.email,
      items: [
        {
          label: "Identity Provider",
          content: <var>{getIdentityProviderDisplayName(loggedInUser)}</var>,
        },
      ],
    },
    {
      title: "Cookie preferences",
      subtitle:
        "We use cookies & similar technologies to improve and personalize your user experience, " +
        "deliver adverts to your interests on third party platforms and analyze our web traffic.",
      // Cookies are not stored in EU therefore we don't show the cookie manager there
      isHidden: !runtimeConfig.urls.cookieManagerUrl,
      items: [
        {
          label: "Cookie policy",
          content: (
            <Box component="div" width="100%" textAlign="end">
              <FaroButtonContained onClick={manageCookies}>
                Edit Cookies
              </FaroButtonContained>
            </Box>
          ),
          shouldAddRightPadding: true,
        },
      ],
    },
  ];
  const filteredPageSectionProps = pageSectionsProps.filter(
    (pageSectionProps) => !pageSectionProps.isHidden
  );

  return (
    <Grid data-testid="MEMBER_PROFILE_ACCOUNT_CONTAINER" mt="45px">
      {filteredPageSectionProps.map((sectionProps, index) => (
        <PageSection
          key={`account-section-wrapper-${index}`}
          isLoading={isLoading}
          isScreenXsAndSmall={isScreenXsAndSmall}
          isTopSection={index === 0}
          isLastSection={index === filteredPageSectionProps.length - 1}
          {...sectionProps}
        />
      ))}
    </Grid>
  );
}
