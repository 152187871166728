// Use Grid version 2
import Grid from "@mui/material/Unstable_Grid2";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { sphereColors } from "@styles/common-colors";
import { updateSidebar } from "@store/ui/ui-slice";
import { Outlet } from "react-router-dom";
import { CONTENT_MAX_WIDTH, PAGE_X_MARGINS } from "@styles/common-styles";
import { CSSProperties, PropsWithChildren, ReactNode, useEffect } from "react";
import { ResendVerification } from "@components/resend-verification";
import { sidebarSelector } from "@store/ui/ui-selector";
import { TrialExpirationBanner } from "@components/banners/trial-expiration-banner";
import { WORKSPACE_SPECIFIC_BANNERS } from "@components/banners/workspace-specific-banner/workspace-specific-banner-constants";
import { WorkspaceSpecificBanner } from "@components/banners/workspace-specific-banner/workspace-specific-banner";
import { AppHeader } from "@components/app-header";

export interface MainPageLayoutProps extends PropsWithChildren {
  /** Flag whether the Sphere XG logo should be shown on the left side of the screen */
  shouldShowLogo?: boolean;

  /** Flag whether the open button should be hidden regardless of the screen size*/
  shouldHideOpenButton?: boolean;

  /** Flag whether the user menu should be shown. Default true */
  shouldShowUserMenu?: boolean;

  /** Flag whether the sidebar should be shown. Default true */
  shouldShowSidebar?: boolean;

  /** Flag whether the progress overview menu should be shown. Default true */
  shouldShowProgressOverviewMenu?: boolean;

  /** Flag whether the Staging Area is shown. Default false */
  isShowingStagingArea?: boolean;

  /** Optional page title to display in the center */
  titleComponent?: ReactNode;

  /** The color of topbar background if need to use different one */
  topbarBackgroundColor?: string;

  /** Indicates if the topbar should have a bottom border */
  shouldShowTopbarBorder?: boolean;

  /** Color of the content background */
  contentBackgroundColor?: CSSProperties["color"];

  /** Logics related to close button */
  closeButton?: {
    /** Whether to show the close button in left or right of screen */
    alignment: "left" | "right";

    /** Callback function that triggers when the close button is closed */
    onClick?(): void;

    /** Whether to show close text or not */
    shouldShowText?: boolean;
  };
}

/**
 * Describes the layout for the Sphere Dashboard website.
 * Includes the header bar, the breadcrumbs and the "Outlet", in which the
 * router will inject every page content.
 *
 * To keep margins consistent across pages, every page should not have neither padding
 * nor margins for the top component, instead we define those margins and paddings here.
 *
 * @returns React main page layout component.
 */
export function MainPageLayout({
  children,
  shouldHideOpenButton = false,
  shouldShowLogo = false,
  shouldShowUserMenu = true,
  shouldShowSidebar = true,
  shouldShowProgressOverviewMenu = true,
  isShowingStagingArea = false,
  closeButton,
  titleComponent,
  topbarBackgroundColor = sphereColors.pureWhite,
  shouldShowTopbarBorder,
  contentBackgroundColor,
}: MainPageLayoutProps): JSX.Element {
  const dispatch = useAppDispatch();
  const sidebar = useAppSelector(sidebarSelector);

  useEffect(() => {
    dispatch(updateSidebar({ ...sidebar, isVisible: shouldShowSidebar }));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Putting sidebar on dependency will create a loop
  }, [dispatch, shouldShowSidebar]);

  return (
    <Grid sx={{ width: "100%", overflow: "auto" }}>
      {/* Banners that show for specific workspaces */}
      {WORKSPACE_SPECIFIC_BANNERS.map((banner, index) => (
        <WorkspaceSpecificBanner key={index} {...banner} />
      ))}

      {/* Banner to remind users to verify email address */}
      <ResendVerification />

      {/* Trial expiration banner */}
      <TrialExpirationBanner />

      {/* Contains the header bar */}
      <AppHeader
        closeButton={closeButton}
        isShowingStagingArea={isShowingStagingArea}
        shouldShowLogo={shouldShowLogo}
        shouldShowProgressOverviewMenu={shouldShowProgressOverviewMenu}
        shouldShowUserMenu={shouldShowUserMenu}
        titleComponent={titleComponent}
        topbarBackgroundColor={topbarBackgroundColor}
        shouldShowTopbarBorder={shouldShowTopbarBorder}
        shouldHideOpenButton={shouldHideOpenButton}
      />

      {/* Contains the rest of the content */}
      <Grid
        data-testid="CONTENT_WRAPPER"
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          px: {
            xs: PAGE_X_MARGINS.xs,
            sm: PAGE_X_MARGINS.sm,
            md: PAGE_X_MARGINS.md,
            lg: PAGE_X_MARGINS.lg,
            xl: PAGE_X_MARGINS.xl,
          },
          paddingBottom: "30px",
          backgroundColor: contentBackgroundColor,
        }}
      >
        <Grid
          data-testid="CONTENT"
          sx={{
            width: "100%",
            maxWidth: {
              xs: CONTENT_MAX_WIDTH.xs,
              sm: CONTENT_MAX_WIDTH.sm,
              md: CONTENT_MAX_WIDTH.md,
              lg: CONTENT_MAX_WIDTH.lg,
              xl: CONTENT_MAX_WIDTH.xl,
            },
          }}
        >
          {/* <Outlet> is a placeholder for the active child route defined in the routes.ts */}
          {children ? children : <Outlet />}
        </Grid>
      </Grid>
    </Grid>
  );
}
