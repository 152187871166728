import { isNumber } from "lodash";
import { EventProps } from "@faro-lotv/foreign-observers";
import { assert, generateGUID, GUID } from "@faro-lotv/foundation";
import {
  CaptureTreeEntityType,
  CaptureTreePointCloudType,
  CreateOrUpdateRegistrationEdgeParams,
  RegistrationEdgeType,
  RegistrationState,
} from "@faro-lotv/service-wires";
import { ElsScanFileUploadTaskContext, UploadedFile } from "@custom-types/file-upload-types";
import { isValidFile } from "@hooks/file-upload-utils";
import { FILE_SIZE_MULTIPLIER, getFileExtension } from "@utils/file-utils";
import { ProjectApi } from "@api/project-api/project-api";
import { LsEdge, ReadLsDataV2Response, LsScan } from "@api/stagingarea-api/stagingarea-api-types";
import { getScanByFilename } from "@api/stagingarea-api/stagingarea-api";
import { APITypes } from "@stellar/api-logic";
import { UUID } from "@stellar/api-logic/dist/api/core-api/api-types";
import { CaptureTreeRootAndClustersByUuid } from "@pages/project-details/project-data-management/import-data/create-revision-for-els-scans";
import { UploadErrorToastType } from "@hooks/data-management/use-upload-error-toast";
import { getIdentityPose } from "@utils/capture-tree-utils";
import { CreateOrUpdateScanEntityParamsWithPC } from "@custom-types/capture-tree-types";

export type EdgeToCreate = CreateOrUpdateRegistrationEdgeParams;

/**
 * List of allowed extensions for importing scan data.
 * "index-v2" & co don't have an extension, so we need to allow all extensions and filter later.
 */
export const ALLOWED_EXTENSIONS_ALL: string[] = [];

/** List of allowed extensions for the scan upload. */
export const ALLOWED_EXTENSIONS_GLS: string[] = ["gls"];

/** Maximum file size of each scan file: 20 GB. Arbitrary choice, but big enough for any Faro scan. */
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const MAX_FILE_SIZE_IN_MB = 20 * FILE_SIZE_MULTIPLIER;

/** Regular expression to extract the UUID (usually UUIDv4) from "UUID.gls" or e.g. "UUID (2).gls". */
export const CONTAINS_UUID_GLS_REGEX = /^.*([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}).*\.gls$/i;

/**
 * TODO(TF-1998) (TEMP DRAFT MODEL) Can be removed once Draft Revision concept is deployed everywhere.
 *
 * True if the Draft Revision concept is deployed to the current environment.
 */
export const hasDraftRevisionConceptEnv = true;

/** @returns True if it's a *.gls file (ELS/Blink scan). */
export function isGLS(fileName: string): boolean {
  return fileName.toLowerCase().endsWith(".gls");
}

/** Extract the scan UUID from LsDataV2 or from the filename. */
export function getGlsUuid(fileName: string, lsDataV2: ReadLsDataV2Response | null): string | undefined {
  return getScanByFilename(fileName, lsDataV2)?.uuid ||
    fileName.match(CONTAINS_UUID_GLS_REGEX)?.[1]?.toLowerCase();
}

/** @returns True if the given GLS file may be uploaded. */
export function isValidGlsFile(file: File, lsDataV2: ReadLsDataV2Response | null): boolean {
  const isValid = isValidFile({
    file,
    allowedExtensions: ALLOWED_EXTENSIONS_GLS,
    maxFileSize: MAX_FILE_SIZE_IN_MB,
  }).isValid;

  // We only allow to upload scans whose UUID (as set by the scanner) is known.
  // Otherwise we can't check for duplicates later.
  return isValid && !!getGlsUuid(file.name, lsDataV2);
}

/** @returns The set of files (scans) whose UUIDs are contained in `uploadedIdsMap`. */
export function getScansAlreadyUploaded(
  files: File[],
  lsDataV2: ReadLsDataV2Response | null,
  uploadedIdsMap: { [key: APITypes.UUID]: boolean }
): Set<File> {
  const filesFound = files.filter((file) => {
    const uuid = getGlsUuid(file.name, lsDataV2);
    return uuid && uploadedIdsMap[uuid];
  });
  return new Set(filesFound);
}

/** @returns True if it's the "index-v2" file from the LsDataV2 format. */
export function isIndexV2(fileName: string): boolean {
  return fileName.toLowerCase() === "index-v2";
}

/** @returns True if it's a file "ls-data/objects/[0-9a-f]" from the LsDataV2 format. */
export function isLsDataObject(file: File): boolean {
  // According to the FW team, there can be max. 16 files "ls-data/objects/[0-9a-f]",
  // and no multi-digit filenames.
  // If we have the relative path available, use it to better filter out irrelevant files,
  // since file.name = [0-9a-f] is quite generic.
  const isLsDataObject = file.webkitRelativePath ?
    file.webkitRelativePath.toLowerCase().includes("ls-data/objects/") :
    undefined;

  return isLsDataObject !== false && (/^[0-9a-f]$/i).test(file.name);
}

interface OtherFilesInfo {
  [extLower: string]: {
    count: number;
    size: number;
  }
}

interface FilesInfoEventProps extends EventProps {
  filesGLS: number;
  filesGLSSize: number;
  filesLsDataV2: number;
  filesLsDataV2Size: number;
  filesOther: OtherFilesInfo;
}

/** @returns Info about files being added (dragged or selected), for tracking. */
export function filesInfoForTracking(files: File[]): FilesInfoEventProps {
  const glsFiles = files.filter((file) => isGLS(file.name));
  const lsDataV2Files = files.filter((file) => !isGLS(file.name) && (isIndexV2(file.name) || isLsDataObject(file)));
  const otherFiles = files.filter((file) => !isGLS(file.name) && !isIndexV2(file.name) && !isLsDataObject(file));

  const otherFilesMap: OtherFilesInfo = {};
  for (const file of otherFiles) {
    const extLower = getFileExtension(file.name);
    otherFilesMap[extLower] = otherFilesMap[extLower] || { count: 0, size: 0 };
    otherFilesMap[extLower].count++;
    otherFilesMap[extLower].size += file.size;
  }

  return {
    filesGLS: glsFiles.length,
    filesGLSSize: glsFiles.reduce((sum, file) => sum + file.size, 0),
    filesLsDataV2: lsDataV2Files.length,
    filesLsDataV2Size: lsDataV2Files.reduce((sum, file) => sum + file.size, 0),
    filesOther: otherFilesMap,
  };
}

/** @returns Info about the data in the LsDataV2 package, for tracking. */
export function lsDataV2InfoForTracking(lsDataV2: ReadLsDataV2Response | null): {
  hasLsDataV2: boolean;
  lsScans: number;
  lsClusters: number;
  lsEdges: number;
  maxTreeLevel: number;
} {
  return {
    hasLsDataV2: !!lsDataV2,
    lsScans: lsDataV2?.scans?.length ?? 0,
    lsClusters: lsDataV2?.clusters?.length ?? 0,
    lsEdges: lsDataV2?.edges?.length ?? 0,
    maxTreeLevel: lsDataV2?.maxTreeLevel ?? 0,
  };
}

interface GetCreateScanEntitiesParamsProps {
  /** List of successfully uploaded scans to add */
  uploadedScans: UploadedFile[];

  /**
   * Map from externalId (UUID) from the scan metadata to RootEntity / ClusterEntity.
   * For existing entities, we store the full objects as returned by the API.
   * For new clusters (created in our revision), we store the partial ClusterEntity (= request body for creation).
   */
  captureTreeRootAndClustersByUuid: CaptureTreeRootAndClustersByUuid;

  /** Scan metadata, to map from filename (*.gls) to scan metadata from LsDataV2. */
  lsDataV2: ReadLsDataV2Response | null;

  /** Latest externalIds of all scans in the main revision, so we can check again for duplicates. */
  existingExternalScanIds: Set<UUID>;
}

/** Workaround for React not allowing us to modify the ElsScanFileUploadTaskContext after each successful upload. */
export interface ExtraUploadContext {
  scanEntitiesParams: CreateOrUpdateScanEntityParamsWithPC[];
}
export const extraUploadContexts: Map<ElsScanFileUploadTaskContext, ExtraUploadContext> = new Map();

/**
 * @returns An array of params required to create capture tree scan entities from the passed uploaded scans
 */
export function getCreateScanEntitiesParams({
  uploadedScans,
  captureTreeRootAndClustersByUuid,
  lsDataV2,
  existingExternalScanIds,
}: GetCreateScanEntitiesParamsProps): CreateOrUpdateScanEntityParamsWithPC[] {
  return uploadedScans.map((uploadedScan) => {
    // `scan` will be undefined in these cases, so we should allow it.
    //   - The user uploaded only the GLS files, without LsDataV2.
    //   - The user added an extra GLS file which is not in LsDataV2.
    // Knowing the scan UUID is still mandatory; it must be found in the *.gls file name then.
    const scan: LsScan | undefined = getScanByFilename(uploadedScan.fileName, lsDataV2);
    // Use the UUID from LsDataV2 if available, otherwise extract it from the filename.
    // Knowing the UUID is important to detect later if a scan was already uploaded, e.g. in case that the user
    // tries to upload the same project again with some additional scans.
    const externalId = scan?.uuid || getGlsUuid(uploadedScan.fileName, null);
    // Unexpected code path, because import-data > isConfirmDisabled > isValidGlsFile verifies that each scan has a UUID.
    assert(externalId, `Logic error: We should only accept scans with a UUID: ${uploadedScan.fileName}`);
    // Check if during our upload, someone else uploaded the same scan.
    // -> We should not try to create it again.
    if (existingExternalScanIds.has(externalId)) {
      return undefined;
    }

    // If someone added e.g. an extra *.gls file to the upload folder, we can't find it in LsDataV2.
    // As a fallback, we put such scans directly under the RootEntity.
    const parentUuid = scan?.parentUuid;
    const parentId = captureTreeRootAndClustersByUuid[parentUuid ?? "root"]?.id;
    assert(parentId, `Logic error: Could not determine parentId for scan: ${uploadedScan.fileName}`);
    return {
      // We need to generate a GUID client-side so we can reference the scan when we create the edges.
      id: generateGUID(),
      parentId,
      type: CaptureTreeEntityType.elsScan,
      name: scan?.name || uploadedScan.fileName,
      createdAt: scan?.recordingTime || "1970-01-01T00:00:00.000Z",
      pose: scan?.pose || getIdentityPose(),
      pointClouds: [
        {
          externalId,
          type: CaptureTreePointCloudType.elsRaw,
          uri: uploadedScan.downloadUrl,
          md5Hash: uploadedScan.md5,
          fileSize: uploadedScan.fileSize,
          fileName: uploadedScan.fileName,
          createdAt: scan?.recordingTime || "1970-01-01T00:00:00.000Z",
        },
      ],
    };
  }).filter((scan) => scan !== undefined);
}

/**
 * Converts an edge from the LsDataV2 format to the Capture Tree format.
 * https://faro01.atlassian.net/wiki/spaces/REG/pages/4016472095/Edge+Registration+JSON+Data+structure
 * https://bitbucket.org/farosw/flow/src/master/src/services/sphere2/client/CaptureTreeRevision.ts
 *
 * @param lsEdge LsDataV2 edge to convert.
 * @param mapNewScanUuidToGuid Map from LsDataV2 scan UUID to Capture Tree scan GUID ("id" attribute).
 * @param mapExistingScanUuidToGuid Map from LsDataV2 scan UUID to Capture Tree scan GUID ("id" attribute).
 * @returns RegistrationEdge, or undefined if we don't support the edge type.
 */
export function lsToCaptureTreeEdge(
  lsEdge: LsEdge,
  mapNewScanUuidToGuid: Map<UUID, GUID>,
  mapExistingScanUuidToGuid: Map<UUID, GUID> | undefined
): EdgeToCreate | undefined {
  if (lsEdge.constraint.type !== "HbRegistrationConstraintObject") {
    return;
  }

  const fscore = lsEdge.constraint.data.quality;
  const { type, trafo } = lsEdge.constraint.data;
  if (type !== "PREREG" && type !== "SLAM") {
    // According to previous discussion with Benny (FW team), we don't create "MANUAL" edges in Capture Tree.
    return;
  }

  const { sourceUuid, targetUuid } = lsEdge;
  const sourceId = mapNewScanUuidToGuid.get(sourceUuid) || mapExistingScanUuidToGuid?.get(sourceUuid);
  const targetId = mapNewScanUuidToGuid.get(targetUuid) || mapExistingScanUuidToGuid?.get(targetUuid);
  assert(sourceId && targetId, "Missing source or target ID for edge");

  return {
    // SMETA-1486: Generating the GUID seems required for edges, otherwise we get this confusing error:
    // "invalid_registration_edge" / "Source and target must be the same as the original edge."
    id: generateGUID(),
    type: type === "SLAM" ? RegistrationEdgeType.slam : RegistrationEdgeType.preReg,
    sourceId,
    targetId,
    data: {
      jsonRevision: 3,
      transformation: trafo,
      metrics: isNumber(fscore) ? {
        fscore,
      } : undefined,
    },
  };
}

/**
 * Returns the Capture Tree edges to create.
 * @param context Upload context.
 * @param scanEntitiesParams Parameters of scans that were uploaded and don't exist yet in the main revision.
 * @returns Array of capture Tree edges to create.
 */
export function getEdgesToCreate(
  context: ElsScanFileUploadTaskContext,
  scanEntitiesParams: CreateOrUpdateScanEntityParamsWithPC[]
): EdgeToCreate[] {
  const mapExistingScanUuidToGuid = new Map<UUID, GUID>(Object.entries(context.captureTreeScanIdByUuid));

  const mapNewScanUuidToGuid = new Map<UUID, GUID>();
  for (const scan of scanEntitiesParams) {
    assert(scan.pointClouds[0].externalId);
    mapNewScanUuidToGuid.set(scan.pointClouds[0].externalId, scan.id);
  }

  // For now, create only those edges that have both source and target in the set of new scans.
  const edgesWithinNewScans = (context.lsDataV2?.edges || []).filter((lsEdge) =>
    lsEdge.sourceUuid && lsEdge.targetUuid &&
    mapNewScanUuidToGuid.has(lsEdge.sourceUuid) && mapNewScanUuidToGuid.has(lsEdge.targetUuid)
  )
    .map((lsEdge) => lsToCaptureTreeEdge(lsEdge, mapNewScanUuidToGuid, /* ignore existing scans */ undefined))
    .filter((edge) => !!edge);

  const edgesBetweenNewAndExistingScans = (context.lsDataV2?.edges || []).filter((lsEdge) => {
    if (!lsEdge.sourceUuid || !lsEdge.targetUuid) {
      return false;
    }
    const isSourceNew = mapNewScanUuidToGuid.has(lsEdge.sourceUuid);
    const isTargetNew = mapNewScanUuidToGuid.has(lsEdge.targetUuid);
    const isSourceExisting = !isSourceNew && mapExistingScanUuidToGuid.has(lsEdge.sourceUuid);
    const isTargetExisting = !isTargetNew && mapExistingScanUuidToGuid.has(lsEdge.targetUuid);
    return (isSourceNew && isTargetExisting) || (isSourceExisting && isTargetNew);
  })
    .map((lsEdge) => lsToCaptureTreeEdge(lsEdge, mapNewScanUuidToGuid, mapExistingScanUuidToGuid))
    .filter((edge) => !!edge);

  return edgesWithinNewScans.concat(edgesBetweenNewAndExistingScans);
}

/**
 * Handles the logic after the upload of ELS scan succeeded:
 * - Adds (creates) successful uploads to the specified cluster of the project revision.
 * - Updates revision to "registered" status and merges it to the main revision.
 *
 * @param projectApiClient
 * @param context Upload context, which allows us to get the parameters of the ScanEntities.
 */
export async function addScansToRevisionAndMergeHelper(
  projectApiClient: ProjectApi,
  context: ElsScanFileUploadTaskContext
): Promise<void> {
  const extraContext = extraUploadContexts.get(context);
  assert(extraContext, "Missing ExtraUploadContext");

  // If we got here, we should have at least one successful upload.
  const { scanEntitiesParams } = extraContext;
  if (scanEntitiesParams.length === 0) {
    throw new Error(UploadErrorToastType.noNewFiles);
  }

  // Having no edges is a valid case:
  // - Consider e.g. a project with a single scan.
  // - The registration backend may also create edges, based on the scan positions.
  const edgesToCreate = getEdgesToCreate(context, scanEntitiesParams);
  if (edgesToCreate.length > 0) {
    await projectApiClient.createOrUpdateRegistrationEdges({
      registrationRevisionId: context.captureTreeRevisionId,
      requestBody: edgesToCreate,
    });
  }

  await projectApiClient.updateRegistrationRevision({
    registrationRevisionId: context.captureTreeRevisionId,
    state: RegistrationState.registered,
  });

  await projectApiClient.applyRegistrationRevision(
    context.captureTreeRevisionId
  );
}
