import { RemoveTaskFn, StartUploadFn, UpdateTaskFn, UploadManagerInterface } from "@custom-types/upload-manager-types";
import { UploadManager } from "@context-providers/file-upload/upload-manager";
import { UploadManagerWorker } from "@context-providers/file-upload/upload-manager-worker";

let isLogEnabled = true;

/** Allow to disable logging so the "npm test" output is not cluttered. */
export function enableCreateUploadManagerLog(isEnabled: boolean): void {
  isLogEnabled = isEnabled;
}

/**
 * Factory function to create an instance of UploadManager
 * @param startTaskInStore Function to start a background task in the store
 * @param updateTaskInStore Function to update the task in the store corresponding to the upload
 * @param removeTaskFromStore Function to remove a task from the store.
 * @returns Instance of an UploadManager
 */
export function createUploadManager(
  startTaskInStore: StartUploadFn,
  updateTaskInStore: UpdateTaskFn,
  removeTaskFromStore: RemoveTaskFn
): UploadManagerInterface {
  try {
    // The developer experience of SharedWorkers is not great in browsers yet,
    // especially too many interactions are required to access a SharedWorker's DevTools.
    // This can be annoying if devs e.g. want to fake a slow/broken internet connection.
    // Using the old UploadManager, everything is reachable from the main window's DevTools.
    if (!globalThis.location.search.includes("uploadManagerWorker=false")) {
      return new UploadManagerWorker(
        startTaskInStore,
        updateTaskInStore,
        removeTaskFromStore
      );
    }
  } catch (error) {
    // We also track DataManagementEvents.startUpload + finishUpload with { ... isSharedWorker: boolean }.
    // Fallback to the UploadManager if SharedWorker is not supported.
    // If the user's browser is expected to support SharedWorker, we could log to Sentry additionally.
    if (isLogEnabled) {
      // eslint-disable-next-line no-console -- Should be visible for devs at least.
      console.error("UploadManagerWorker could not be initialized, using fallback.", error);
    }
  }
  return new UploadManager(
    startTaskInStore,
    updateTaskInStore,
    removeTaskFromStore
  );
}
