import { BaseProjectIdProps, BaseUserIdProps } from "@custom-types/sdb-company-types";
import { Stack } from "@mui/material";
import { StepState, WorkflowState } from "@pages/project-details/project-data-management/data-management-types";
import { ProcessStep } from "@pages/project-details/project-data-management/data-management-stepper/process-step";
import { PublishStep } from "@pages/project-details/project-data-management/data-management-stepper/publish-step";
import { RegisterStep } from "@pages/project-details/project-data-management/data-management-stepper/register-step";
import { UploadStep } from "@pages/project-details/project-data-management/data-management-stepper/upload-step";

interface Props extends BaseProjectIdProps, BaseUserIdProps {
  /** Main state of the Staging Area workflow. See DataManagementWorkflow. */
  state: WorkflowState;
  /** State of the upload step. */
  uploadStepState: StepState;
  /** State of the processing step. */
  processStepState: StepState;
  /** State of the registration step. */
  registerStepState: StepState;
  /** State of the publishing step. */
  publishStepState: StepState;
  /** Upload progress value from 0 to 100. */
  uploadProgress: number;
  /** Processing progress value from 0 to 100. */
  processProgress: number;
  /** Registration progress value from 0 to 100. */
  registerProgress: number;
  /** Setter for showing or hiding the upload dialog. */
  setIsUploadDialogOpen(isUploadDialogOpen: boolean): void;
  /** ID of the registered entity. */
  registrationId?: string;
}

/**
 * The DataManagementStepper is a replacement for MUI's Stepper which doesn't support progress bars between the steps.
 * This component and its sub components are currently tailored for the use in the Staging Area with many adjustments
 * for this use case.
 */
export function DataManagementStepper({
  state,
  uploadStepState,
  processStepState,
  registerStepState,
  publishStepState,
  uploadProgress,
  processProgress,
  registerProgress,
  setIsUploadDialogOpen,
  registrationId,
  projectId,
  userId,
}: Props): JSX.Element {
  return (
    <Stack direction="row" spacing={0}>
      <UploadStep
        uploadStepState={uploadStepState}
        projectId={projectId}
        setIsUploadDialogOpen={setIsUploadDialogOpen}
        progress={uploadProgress}
      />

      <ProcessStep
        processStepState={processStepState}
        state={state}
        projectId={projectId}
        userId={userId}
        progress={processProgress}
      />

      <RegisterStep
        registerStepState={registerStepState}
        state={state}
        projectId={projectId}
        registrationId={registrationId}
        progress={registerProgress}
      />

      <PublishStep
        publishStepState={publishStepState}
        projectId={projectId}
        registrationId={registrationId}
      />
    </Stack>
  );
}
