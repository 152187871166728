import {
  CaptureTreeEntity,
  CaptureTreeEntityRevision,
  CaptureTreeEntityType,
  CaptureTreePointCloudType,
  RevisionStatus,
} from "@faro-lotv/service-wires";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import {
  allCaptureTreeRevisionsAdapter,
  captureTreeAdapter,
  captureTreeForOpenDraftRevisionAdapter,
} from "@store/capture-tree/capture-tree-slice";
import { sdbBackgroundTasksAdapter } from "@store/sdb-background-tasks/sdb-background-tasks-slice";
import { SdbBackgroundTask, SdbBackgroundTaskStates } from "@custom-types/sdb-background-tasks-types";
import { CaptureTreeState } from "@store/capture-tree/capture-tree-slice-types";
import { GUID } from "@faro-lotv/foundation";
import { isOpenDraftRevision } from "@utils/capture-tree-utils";
import { CaptureTreeRevision, ScanEntity } from "@custom-types/capture-tree-types";

/** Returns all the capture tree entities (for the current main/draft revision of the selected project) */
export const captureTreeSelector: (
  state: RootState
) => CaptureTreeEntity[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return captureTreeAdapter
      .getSelectors()
      .selectAll(state.captureTree.captureTree);
  }
);

/** Returns all the capture tree entities (for the open draft revision of the selected project) */
export const captureTreeForOpenDraftRevisionSelector: (
  state: RootState
) => CaptureTreeEntityRevision[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return captureTreeForOpenDraftRevisionAdapter
      .getSelectors()
      .selectAll(state.captureTree.captureTreeForOpenDraftRevision);
  }
);

/** Returns true if there is an open draft revision, and if that revision contains any changes. */
export const hasAnyDraftChangesSelector: (
  state: RootState
) => boolean = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return captureTreeForOpenDraftRevisionAdapter
      .getSelectors()
      .selectAll(state.captureTree.captureTreeForOpenDraftRevision)
      .some((entity) => entity.status !== RevisionStatus.initialized);
  }
);

/** Returns the fetching status of the capture tree entities (for the current main/draft revision of the selected project) */
export const fetchingStatusCaptureTreeSelector: (
  state: RootState
) => CaptureTreeState["fetchingStatus"]["captureTree"] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.captureTree.fetchingStatus.captureTree;
    }
  );

/** Returns the fetching status of the capture tree entities (for the current draft revision of the selected project) */
export const fetchingStatusCaptureTreeForOpenDraftRevisionSelector: (
  state: RootState
) => CaptureTreeState["fetchingStatus"]["captureTreeForOpenDraftRevision"] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.captureTree.fetchingStatus.captureTreeForOpenDraftRevision;
    }
  );

/** Returns whether capture tree entities have been fetched at least once */
export const hasFetchedCaptureTreeSelector: (
  state: RootState
) => boolean = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.captureTree.hasFetched.hasFetchedCaptureTree;
  }
);

/** Returns whether capture tree entities have been fetched at least once */
export const hasFetchedCaptureTreeForOpenDraftRevisionSelector: (
  state: RootState
) => boolean = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.captureTree.hasFetched.hasFetchedCaptureTreeForOpenDraftRevision;
  }
);

/** Returns whether capture tree entities have been fetched at least once */
export const hasFetchCaptureTreeDataErrorSelector: (
  state: RootState
) => boolean = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.captureTree.fetchingStatus.hasFetchCaptureTreeDataError;
  }
);

/** Returns all the revisions of the current project */
export const allCaptureTreeRevisionsSelector: (
  state: RootState
) => CaptureTreeRevision[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return allCaptureTreeRevisionsAdapter
      .getSelectors()
      .selectAll(state.captureTree.allCaptureTreeRevisions)
      // Sort by creation date, newest first.
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  }
);

/** Returns the fetching status of all the revisions of the current project */
export const fetchingStatusAllCaptureTreeRevisionsSelector: (
  state: RootState
) => CaptureTreeState["fetchingStatus"]["allCaptureTreeRevisions"] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.captureTree.fetchingStatus.allCaptureTreeRevisions;
    }
  );

/** Returns whether all revisions of the current project have been fetched at least once */
export const hasFetchedAllCaptureTreeRevisionsSelector: (
  state: RootState
) => boolean = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.captureTree.hasFetched.hasFetchedAllCaptureTreeRevisions;
  }
);

/** Returns the open (= neither merged or canceled) Draft revision of the project, or undefined. */
export const openDraftRevisionsSelector: (
  state: RootState
) => CaptureTreeRevision | undefined = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return allCaptureTreeRevisionsAdapter
      .getSelectors()
      .selectAll(state.captureTree.allCaptureTreeRevisions)
      .find(isOpenDraftRevision);
  }
);

/**
 * TODO(TF-1998) (TEMP DRAFT MODEL) Can be removed once Draft Revision concept is deployed everywhere.
 *
 * Returns true if the Draft Revision concept is deployed to the current environment,
 * or false if not, or undefined if we don't know (because there are no revisions in the current project).
 */
export const hasDraftRevisionConceptSelector: (
  state: RootState
) => boolean | undefined = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const revisions = allCaptureTreeRevisionsAdapter
      .getSelectors()
      .selectAll(state.captureTree.allCaptureTreeRevisions);
    // If Draft Revisions are available, all revisions have a revisionType of null or "Draft".
    return revisions.length > 0 ? (revisions[0].revisionType !== undefined) : undefined;
  }
);

/**
 * Gets a capture tree entity by providing its ID
 *
 * * @param id ID of the capture tree entity
 */
export function captureTreeEntityByIdSelector(
  id: GUID
): (state: RootState) => CaptureTreeEntity | undefined {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      if (!id) {
        return undefined;
      }

      const entities = captureTreeSelector(state);
      return entities.find((entity) => entity.id === id);
    }
  );
}

/**
 * @returns A string representing the path to the cluster where the capture tree entity is located
 *
 * @param id ID of the capture tree entity
 */
export function captureTreeEntityClusterPathSelector(
  id: GUID
): (state: RootState) => string {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      let entity = captureTreeEntityByIdSelector(id)(state);

      if (!entity) {
        return "";
      }

      const pathArray: string[] = [];

      if (entity.type === CaptureTreeEntityType.cluster) {
        pathArray.unshift(entity.name);
      }

      while (entity && entity.parentId) {
        entity = captureTreeEntityByIdSelector(entity.parentId)(state);

        if (entity && entity.type === CaptureTreeEntityType.cluster) {
          pathArray.unshift(entity.name);
        }
      }

      return pathArray.join("/");
    }
  );
}

/**
 * @returns true if the capture tree scan entity is processing:
 *          It doesn't have an E57 point cloud yet, and there are unfinished tasks related to it.
 *
 * @param id ID of the capture tree scan entity
 */
export function isCaptureTreeScanEntityProcessingSelector(
  entity: ScanEntity
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      // Early out if E57 file already exists.
      // We currently don't check if the PointCloudStream ("scan point cloud") exists:
      // - It's only relevant for Registration UI ("Inspect").
      // - It seems not exposed in the Capture Tree yet.
      if (entity.pointClouds?.some((pc) => pc.type === CaptureTreePointCloudType.e57)) {
        return false;
      }

      // Check the task states got from ProgressAPI.
      const selectedScanTasks: SdbBackgroundTask[] = getSelectedScanTasks(entity)(state);
      const checkStates: SdbBackgroundTaskStates[] = ["Pending", "Created", "Scheduled", "Started"];
      // We care only about the newest task as they can theoretically be recreated on failure or so.
      if (selectedScanTasks[0]?.state && checkStates.includes(selectedScanTasks[0].state)) {
        return true;
      }

      // There are no active tasks found.
      return false;
    }
  );
}

/**
 * @returns true if the capture tree scan entity has processing errors.
 * To check if a scan entity has errors we look at the relevant tasks from ProgressAPI.
 *
 * @param id ID of the capture tree scan entity
 */
export function hasCaptureTreeScanEntityTaskErrorsSelector(
  entity: ScanEntity
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      // Early out if E57 file already exists.
      // We currently don't check if the PointCloudStream ("scan point cloud") exists:
      // - It's only relevant for Registration UI ("Inspect").
      // - It seems not exposed in the Capture Tree yet.
      if (entity.pointClouds?.some((pc) => pc.type === CaptureTreePointCloudType.e57)) {
        return false;
      }

      // Check the task states got from ProgressAPI.
      const selectedScanTasks: SdbBackgroundTask[] = getSelectedScanTasks(entity)(state);
      const errorStates: SdbBackgroundTaskStates[] = ["Aborted", "Failed"];
      // We care only about the newest task as they can theoretically be recreated on failure or so.
      if (selectedScanTasks[0]?.state && errorStates.includes(selectedScanTasks[0].state)) {
          return true;
      }

      // There are no failed tasks found.
      return false;
    }
  );
}

/**
 * @returns All the tasks related to the capture tree scan entity ordered by creation date, newest first.
 *
 * @param id ID of the capture tree scan entity
 */
function getSelectedScanTasks(entity: ScanEntity): (state: RootState) => SdbBackgroundTask[] {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      if (!entity.pointClouds?.length) {
        return [];
      }

      // The "ElsRaw" point cloud would be sufficient to find the "ProcessElsRawScan" task,
      // but let's already make the code a bit more generic.
      const pointCloudIds = new Set(entity.pointClouds.map((pc) => pc.id));

      // Read all tasks from the store got from ProgressAPI and filter out the ones related to the scan entity.
      const tasks = sdbBackgroundTasksAdapter
        .getSelectors()
        .selectAll(state.sdbBackgroundTasks);

      // Keep only tasks that include any point cloud ID of the scan entity.
      const selectedScanTasks = tasks.filter((task) =>
        !!task.context?.elementId && pointCloudIds.has(task.context.elementId)
      );

      // Sorted from newest creation date to oldest.
      selectedScanTasks.sort((a, b) => {
        const aCreatedAt = new Date(a.createdAt).getTime();
        const bCreatedAt = new Date(b.createdAt).getTime();
        return bCreatedAt - aCreatedAt;
      });

      return selectedScanTasks;
    }
  );
}
