import { useCallback, useState } from "react";
import { useProgressApiClient } from "@api/progress-api/use-progress-api-client";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { FaroButton } from "@components/common/faro-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { CaptureTreeRevision } from "@custom-types/capture-tree-types";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { CaptureTreeEntityRevision } from "@faro-lotv/service-wires";
import { fetchCaptureTreeData, fetchMainEntitiesAndTasks, fetchRevisionsAndDraftEntities } from "@hooks/data-management/use-data-management";
import { useToast } from "@hooks/use-toast";
import { captureTreeForOpenDraftRevisionSelector, openDraftRevisionsSelector } from "@store/capture-tree/capture-tree-selectors";
import { useAppSelector, useAppDispatch } from "@store/store-helper";
import { colorConst } from "@styles/common-colors";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { changeSummaryForTracking, summarizeRevisionChanges, wasDraftUpdated } from "@utils/capture-tree-changes";

interface Props extends BaseProjectIdProps {
  isDisabled: boolean;
  /** Flag if the button is only visible & enabled because of dev mode. */
  isDevMode?: boolean;
}

export function PublishButton({ projectId, isDisabled, isDevMode }: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const projectApiClient = useProjectApiClient({ projectId });
  const progressApiClient = useProgressApiClient({ projectId: projectId.toString() });
  const dispatch = useAppDispatch();
  const { showToast } = useToast();
  const { handleErrorWithToast } = useErrorContext();
  const openDraftRevisionRender = useAppSelector(openDraftRevisionsSelector);
  const openDraftEntitiesRender = useAppSelector(captureTreeForOpenDraftRevisionSelector);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  /**
   * Ask for confirmation, then publish the draft revision.
   * If the project was updated in the meantime, show a warning and early out.
   */
  const publishDraft = useCallback(async () => {
    setIsLoading(true);

    let openDraftRevision: CaptureTreeRevision | undefined;
    let openDraftEntities: CaptureTreeEntityRevision[] | undefined;
    try {
      // Fetch again, to ensure that there is something to publish.
      const result = await fetchRevisionsAndDraftEntities(dispatch, projectApiClient);
      openDraftRevision = result.openDraftRevision;
      openDraftEntities = result.openDraftEntities;

      // Verify that the state shown on the page still matches the latest state.
      if (!openDraftRevisionRender || !openDraftRevision ||
        wasDraftUpdated(openDraftRevisionRender, openDraftEntitiesRender, openDraftRevision, openDraftEntities)
      ) {
        // Try to fetch the rest of the data to show the user all changes on the page.
        await fetchMainEntitiesAndTasks(dispatch, projectApiClient, progressApiClient)
          .catch(() => undefined);
        showToast({
          message: "The project has been updated by another user or application. Please review the changes and try again.",
          type: "warning",
        });
        setIsLoading(false);
        return;
      }
    } catch (error) {
      handleErrorWithToast({
        id: `publishDraftRevision-${new Date().toString()}`,
        error,
        title: "Failed to fetch latest project state before publishing. Please reload the page to try again.",
      });
      setIsLoading(false);
      return;
    }

    trackEvent({
      name: DataManagementEvents.publish,
      props: {
        draftRevision: openDraftRevision.id,
        changeSummary: changeSummaryForTracking(summarizeRevisionChanges(openDraftEntities)),
      },
    });

    try {
      await projectApiClient.publishDraftRevision(openDraftRevision.id);
    } catch (error) {
      handleErrorWithToast({
        id: `publishDraftRevision-${new Date().toString()}`,
        error,
        title: "Failed to publish the project. Please reload the page to try again.",
      });
    }
    await fetchCaptureTreeData(dispatch, projectApiClient, progressApiClient)
      // Not important. The UI will update a bit later.
      .catch(() => undefined);
    setIsLoading(false);
  }, [
    dispatch, handleErrorWithToast, openDraftEntitiesRender, openDraftRevisionRender, progressApiClient, projectApiClient,
    showToast, trackEvent,
  ]);

  const tooltip = isDisabled ? "Please wait until the data has been processed." : "Publish the data.";
  const sx = isDevMode ? {
    backgroundColor: colorConst.devFeature,
    "&:hover": {
      backgroundColor: colorConst.devFeature,
      filter: "brightness(90%)",
    },
  } : undefined;

  return (
    <SphereTooltip title={tooltip} dataTestId="sa-publish-tooltip">
      <FaroButton
        isDisabled={isDisabled || isLoading}
        sx={sx}
        onClick={() => void publishDraft()}
      >
        Publish{isDevMode ? " (dev)" : ""}
      </FaroButton>
    </SphereTooltip>
  );
}
