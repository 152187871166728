import { Box, useMediaQuery, useTheme } from "@mui/material";
// Use Grid version 2, because it will be the stable version in the newer MUI versions
import Grid from "@mui/material/Unstable_Grid2";
import { sphereColors } from "@styles/common-colors";
import MenuIcon from "@assets/icons/generic-menu_l.svg?react";
import { updateSidebar } from "@store/ui/ui-slice";
import { Searchbar } from "@components/header/searchbar";
import { UserMenu } from "@components/header/user-menu";
import { FaroBreadcrumbs } from "@components/header/faro-breadcrumbs";
import { ProjectOverviewButton } from "@components/header/project-overview-button";
import { HEADER_HEIGHT } from "@utils/ui-utils";
import { withEllipsis } from "@styles/common-styles";
import { FaroIconButton } from "@components/common/faro-icon-button";
import FaroSphereLogo from "@assets/icons/faro-sphere-xg-logo-color.svg?react";
import { ProgressOverviewMenu } from "@components/progress-overview/progress-overview-menu";
import { FaroHelpCenterMenu } from "@components/header/faro-help-center-menu";
import { useAppDispatch } from "@store/store-helper";
import { MainPageLayoutProps } from "@components/main-page-layout";
import { useMemo } from "react";
import CloseIcon from "@assets/icons/new/close_24px.svg?react";

type Props = Pick<
  MainPageLayoutProps,
  | "shouldShowLogo"
  | "isShowingStagingArea"
  | "topbarBackgroundColor"
  | "shouldShowTopbarBorder"
  | "shouldHideOpenButton"
  | "shouldShowProgressOverviewMenu"
  | "shouldShowUserMenu"
  | "titleComponent"
  | "closeButton"
>;

export function AppHeader({
  shouldShowLogo,
  isShowingStagingArea,
  topbarBackgroundColor,
  shouldShowTopbarBorder,
  shouldHideOpenButton,
  shouldShowProgressOverviewMenu,
  shouldShowUserMenu,
  titleComponent,
  closeButton,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isScreenSmallAndLarger = useMediaQuery(theme.breakpoints.up("sm"));

  /** The content for the close button */
  const closeButtonContent = useMemo(() => {
    if (closeButton) {
      return (
        <Box component="div">
          <FaroIconButton component={CloseIcon} onClick={closeButton.onClick} />
          {closeButton.shouldShowText ? "Close" : ""}
        </Box>
      );
    }
  }, [closeButton]);

  return (
    <Grid
      container
      sx={{
        // The logo needs a smaller padding on the left side
        pl: shouldShowLogo ? "0.5rem" : "1.125rem",
        pr: "0.5rem",
        pt: isShowingStagingArea ? "0" : "0.375rem",
        pb: "0.125rem",
        backgroundColor: topbarBackgroundColor,
        maxHeight: "7.5rem",
        minHeight: HEADER_HEIGHT,
        borderBottom: shouldShowTopbarBorder
          ? `1px solid ${sphereColors.gray200}`
          : "",
        justifyContent: "space-between",
      }}
      data-testid="HEADER_CONTAINER"
      alignItems="center"
      justifyContent="space-between"
      flexWrap={isShowingStagingArea ? "nowrap" : "wrap"}
      width="100%"
      position={"sticky"}
      top={0}
      zIndex={1}
    >
      {/* Left side of the top-bar */}
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          flexBasis: 0,
        }}
      >
        {/* Large button in the Staging Area to go back to the project overview */}
        {isShowingStagingArea && <ProjectOverviewButton />}

        {shouldShowLogo && (
          <Box component="div">
            <FaroSphereLogo />
          </Box>
        )}

        {closeButton?.alignment === "left" && closeButtonContent}

        {/* Button to open drawer */}
        {!isScreenSmallAndLarger && !shouldHideOpenButton && (
          <Grid alignItems="center">
            <Box
              component="div"
              sx={{
                color: sphereColors.gray800,
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "28px",
                letterSpacing: "0.16px",
              }}
            >
              <FaroIconButton
                aria-label="open drawer"
                iconButtonProps={{
                  edge: "start",
                  sx: {
                    mr: 2,
                    display: { sm: "none" },
                  },
                }}
                component={MenuIcon}
                iconSize="20px"
                onClick={() => {
                  dispatch(updateSidebar({ isOpenOnMobile: true }));
                }}
              />
            </Box>
          </Grid>
        )}

        {isScreenSmallAndLarger && (
          <Grid alignItems="center">
            <FaroBreadcrumbs />
          </Grid>
        )}
      </Box>

      {/* Center of the top-bar */}
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          ...withEllipsis,
        }}
      >
        {titleComponent && (
          <Box
            sx={{
              paddingLeft: "10px",
              paddingRight: "10px",
              ...withEllipsis,
            }}
          >
            {titleComponent}
          </Box>
        )}
      </Box>

      {/* Right side of the top-bar */}
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          flexBasis: 0,
          justifyContent: "right",
        }}
      >
        {/* Search bar */}
        <Searchbar />
        {/* Icons and buttons */}
        <Box
          component="div"
          sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
        >
          <FaroHelpCenterMenu />

          {shouldShowProgressOverviewMenu && <ProgressOverviewMenu />}

          {shouldShowUserMenu && <UserMenu />}

          {closeButton?.alignment === "right" && closeButtonContent}
        </Box>
      </Box>
    </Grid>
  );
}
