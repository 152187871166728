import { ProjectApi } from "@api/project-api/project-api";
import { GUID } from "@faro-lotv/foundation";
import {
  CaptureApiClient,
  CaptureTreeEntity,
  createMutationDeleteCaptureTreeEntities,
  RegistrationState,
} from "@faro-lotv/service-wires";
import { fetchAllCaptureTreeRevisions } from "@store/capture-tree/capture-tree-thunks";
import { AppDispatch } from "@store/store-helper";
import { isElsScanFileUploadTaskContext } from "@custom-types/file-upload-type-guards";
import { ReturnFunction } from "@hooks/data-management/use-cancel-revision";
import {
  FileUploadTask,
} from "@custom-types/file-upload-types";
import {
  UploadManagerInterface,
} from "@custom-types/upload-manager-types";
import { isTaskInProgress } from "@hooks/upload-tasks/upload-tasks-utils";
import { LogEventParams } from "@utils/track-event/use-track-event";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import {
  CaptureTreeEntityRevision,
  RevisionStatus,
} from "@faro-lotv/service-wires";
import { assert } from "@faro-lotv/foundation";
import { changeSummaryForTracking, RevisionChangeSummary } from "@utils/capture-tree-changes";
import { isScanEntity } from "@utils/capture-tree-utils";
import { ScanEntity, CaptureTreeRevision } from "@custom-types/capture-tree-types";
import { WorkflowState } from "@pages/project-details/project-data-management/data-management-types";

/** Delete entities from Capture Tree, using a mutation. */
async function deleteCaptureTreeEntities(projectApiClient: ProjectApi, entities: CaptureTreeEntity[]): Promise<void> {
  assert(entities.length > 0, "No entities to delete.");
  // Remove scans from capture tree.
  const mutation = createMutationDeleteCaptureTreeEntities(
    entities.map((entity) => entity.id),
    CaptureApiClient.dashboard
  );
  await projectApiClient.applyMutations([mutation]);
}

/**
 * Find all relevant revisions for the current project.
 * Returns all revisions that are merged and created by the considered clients.
 * If a revision ID is provided, all revisions before this revision are removed.
 */
export async function getClientMergedRevisions(
  dispatch: AppDispatch,
  projectApiClient: ProjectApi,
  skipUntilRevisionId: GUID | null = null
): Promise<CaptureTreeRevision[]> {
  const allRevisions = await dispatch(fetchAllCaptureTreeRevisions({ projectApiClient })).unwrap();
  // Sort by creation date, newest first.
  allRevisions.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

  // Remove first n revisions until revision x.
  if (skipUntilRevisionId) {
    for (let i = 0; i < allRevisions.length; i++) {
      if (allRevisions[i].id === skipUntilRevisionId) {
        allRevisions.splice(0, i);
        // Example:
        // allRevisions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        // skipUntilRevisionId = 5
        // => allRevisions = [5, 6, 7, 8, 9, 10]
        break;
      }
    }
  }

  const consideredClients = [CaptureApiClient.dashboard, CaptureApiClient.stream, CaptureApiClient.scene];
  return allRevisions.filter((revision) =>
    revision.state === RegistrationState.merged &&
    revision.createdByClient &&
    consideredClients.includes(revision.createdByClient)
  );
};

/**
 * Cancels the upload step and removes the tasks from the store.
 */
export async function cancelUploads(
  captureTreeRevisionId: GUID,
  projectApiClient: ProjectApi,
  cancelRevision: ReturnFunction,
  uploadTasks: FileUploadTask[],
  uploadManager: UploadManagerInterface,
  trackEvent: (params: LogEventParams) => void
): Promise<void> {
  // If the user has opened the dialog shortly before the upload has finished, it might now be too late to cancel.
  // Canceling the revision first makes sure that useAddScansToRevisionAndMerge() can detect it.
  const revision = await projectApiClient.getRegistrationRevision(captureTreeRevisionId);

  if (revision.state === RegistrationState.started) {
    await cancelRevision(projectApiClient, captureTreeRevisionId);
  }

  // We also need to cancel aborted and successful tasks, otherwise they would stay on the page.
  // E.g. smaller scans might be successful before the user cancels the upload.
  const [tasksCanceledTotal, tasksCanceledInProgress] = cleanupUploadTasks(uploadTasks, uploadManager);

  trackEvent({
    name: DataManagementEvents.cancelImport,
    props: {
      workflowState: "upload",
      tasksCanceledTotal,
      tasksCanceledInProgress,
      captureTreeRevisionId,
      revisionState: revision.state,
    },
  });
}

/**
 * TODO(TF-1998) (TEMP DRAFT MODEL) Can be removed once Draft Revision concept is deployed everywhere.
 *
 * Cancels the processing step and searches for the last revision with added scans to remove them.
 */
export async function cancelProcessing(
  projectApiClient: ProjectApi,
  uploadTasks: FileUploadTask[],
  uploadManager: UploadManagerInterface,
  dispatch: AppDispatch,
  trackEvent: (params: LogEventParams) => void
): Promise<void> {
  // First, let's see how this works:
  // In general, we show project in "Processing" step when:
  // - latest revision is merged.
  // - capture tree from main revision have scans that are missing E57 or have some ProcessElsRawScan tasks running.
  // So, since the UI already does half job for us, we need:
  // - get list of scans missing E57 from capture tree.
  // - find merged revision that added those scans.
  // - assert that all scans were added in that revision.
  // - merge mutation to remove those scans from capture tree.
  // - show the user new state.

  // Get fresh capture tree entities for main revision.
  const captureTreeEntities = await projectApiClient.getCaptureTree();
  const scanEntities = captureTreeEntities.filter(isScanEntity);
  // Find scans that are missing E57, this tells us the project is in "Processing" step.
  // Note: there might exist some scans added in previous revisions that are not fully processed,
  //       e.g. failed to process in some previous revision, so we should be careful here.
  const scansMissingE57 = scanEntities.filter((entity) => !entity.pointClouds?.some((pc) => pc.type === "E57"));
  assert(scansMissingE57.length > 0, "There should be at least one scan missing E57. Abort.");

  // Now, find the first merged revisions where scans missing E57 are found.
  // This might delete wrong scans if some other client added new scans in the meantime,
  // but this is what we agreed on for now, to focus on the latest revision.
  const clientMergedRevisions = await getClientMergedRevisions(dispatch, projectApiClient);
  // Find the first recent merged revision with added scans.
  let addedScans: ScanEntity<CaptureTreeEntityRevision>[] = [];
  let addedScansRevision: CaptureTreeRevision | undefined;
  for (const revision of clientMergedRevisions) {
    const entities = await projectApiClient.getCaptureTreeForRegistrationRevision(revision.id);
    const scanEntities = entities.filter(isScanEntity);
    addedScans = scanEntities.filter((entity) => entity.status === RevisionStatus.added);
    const hasScansMissingE57 = addedScans.some((scan) => scansMissingE57.map((entity) => entity.id).includes(scan.id));
    if (hasScansMissingE57) {
      addedScansRevision = revision;
      break;
    }
  }
  assert(addedScansRevision, "Could not find revision with added scans missing E57. Abort.");

  // Remove scans from capture tree.
  await deleteCaptureTreeEntities(projectApiClient, addedScans);

  // We also need to remove upload tasks, otherwise they would stay on the page.
  cleanupUploadTasks(uploadTasks, uploadManager);

  // Amplitude event.
  trackEvent({
    name: DataManagementEvents.cancelImport,
    props: {
      workflowState: "processing",
      addedScansRevisionId: addedScansRevision.id,
      addedScansRemoved: addedScans.length,
    },
  });
}

export async function cancelRegistrationsAndDraftRevision(
  projectApiClient: ProjectApi,
  workflowState: WorkflowState,
  revisions: CaptureTreeRevision[],
  openDraftRevision: CaptureTreeRevision,
  changeSummary: RevisionChangeSummary,
  uploadTasks: FileUploadTask[],
  uploadManager: UploadManagerInterface,
  cancelRevision: ReturnFunction,
  trackEvent: (params: LogEventParams) => void
): Promise<void> {
  // Cancel any revisions created by the registration backend, to stop the registration workers from working on obsolete data.
  const registrationRevisionsToCancel = revisions.filter((revision) =>
    revision.state !== RegistrationState.merged && revision.state !== RegistrationState.canceled &&
    revision.id !== openDraftRevision.id &&
    revision.createdByClient === CaptureApiClient.registrationBackend
  );
  for (const revision of registrationRevisionsToCancel) {
    await cancelRevision(projectApiClient, revision.id);
  }

  // ...and finally cancel the draft revision.
  // This should be done last, otherwise ProjectAPI might automatically create a new draft revision
  // if there are still other open revisions.
  await cancelRevision(projectApiClient, openDraftRevision.id);

  // We also need to remove upload tasks, otherwise they would stay on the page.
  cleanupUploadTasks(uploadTasks, uploadManager);

  // Amplitude event.
  trackEvent({
    name: DataManagementEvents.cancelImport,
    props: {
      isCancelDraftRevision: true,
      workflowState,
      changeSummary: changeSummaryForTracking(changeSummary),
    },
  });
}


/**
 * TODO(TF-1998) (TEMP DRAFT MODEL) Can be removed once Draft Revision concept is deployed everywhere.
 *
 * Cancels the registration revision and searches for the last revision with added scans to remove them.
 */
export async function cancelRegistration(
  registrationRevisionId: GUID,
  projectApiClient: ProjectApi,
  uploadTasks: FileUploadTask[],
  uploadManager: UploadManagerInterface,
  cancelRevision: ReturnFunction,
  dispatch: AppDispatch,
  trackEvent: (params: LogEventParams) => void
): Promise<void> {
  // Cancel only if the registration is in a state where the user can still cancel it.
  const statesToCancel = [
    RegistrationState.started,
    RegistrationState.userModified,
    RegistrationState.cloudRegistrationStarted,
  ];
  // Check the current registration state.
  const registrationRevision = await projectApiClient.getRegistrationRevision(registrationRevisionId);
  assert(registrationRevision.createdByClient === CaptureApiClient.registrationBackend,
    "Only RegistrationBackend revisions can be canceled.");

  // If the registration is in a state where the user can't cancel it, we should not proceed.
  assert(statesToCancel.includes(registrationRevision.state),"The registration is in a state where it can't be canceled.");

  // Find the correct revision to remove scans from capture tree.
  // Note: RegistrationBackend revisions contains all scans, so we need to search for a specific previous
  // Dashboard revision with added scans that are contained in the registration revision we just cancelled.
  // Note that we defined registration revision here so we search for the revision that added scans just before it.
  const clientMergedRevisions = await getClientMergedRevisions(dispatch, projectApiClient, registrationRevisionId);
  // Find the first recent merged revision with added scans.
  let addedScans: ScanEntity<CaptureTreeEntityRevision>[] = [];
  let addedScansRevision: CaptureTreeRevision | undefined;
  for (const revision of clientMergedRevisions) {
    // Skip deleting if there was already a successful registration revision in between.
    // Theoretically, this can only happen if developer mode is enabled where registration task was started again.
    if (revision.createdByClient === CaptureApiClient.registrationBackend && revision.state === RegistrationState.merged) {
      break;
    }
    const entities = await projectApiClient.getCaptureTreeForRegistrationRevision(revision.id);
    const scanEntities = entities.filter(isScanEntity);
    addedScans = scanEntities.filter((entity) => entity.status === RevisionStatus.added);
    if (addedScans.length) {
      addedScansRevision = revision;
      break;
    }
  }
  assert(addedScansRevision, "Could not find revision with added scans. Abort.");

  // To avoid selecting wrong revision and possibly deleting wrong scans,
  // we make sure all added scans are in the registration revision we cancelled as well.
  // Otherwise, probably other client added new revision with new scans in the meantime.
  const registrationEntities = await projectApiClient.getCaptureTreeForRegistrationRevision(registrationRevisionId);
  const registrationScanEntities = registrationEntities.filter(isScanEntity).map((entity) => entity.id);
  const missingScanIds = addedScans.filter((scan) => !registrationScanEntities.includes(scan.id));
  assert(missingScanIds.length === 0, "Some scans are missing in the registration revision. Abort.");

  // Cancel the registration revision.
  await cancelRevision(projectApiClient, registrationRevisionId);

  // Remove scans from capture tree.
  await deleteCaptureTreeEntities(projectApiClient, addedScans);

  // We also need to remove upload tasks, otherwise they would stay on the page.
  cleanupUploadTasks(uploadTasks, uploadManager);

  // Amplitude event.
  trackEvent({
    name: DataManagementEvents.cancelImport,
    props: {
      workflowState: "registration",
      registrationRevisionId,
      registrationRevisionState: registrationRevision.state,
      addedScansRevisionId: addedScansRevision?.id ?? "not-found",
      addedScansRemoved: addedScans.length,
    },
  });
}

/**
 * Cancels the upload tasks and removes them from the store.
 */
export function cleanupUploadTasks(
  uploadTasks: FileUploadTask[],
  uploadManager: UploadManagerInterface
): [number, number] {
  const tasksToCancel = uploadTasks.filter((task) => isElsScanFileUploadTaskContext(task.context));
  const tasksToCancelInProgress = tasksToCancel.filter((task) => isTaskInProgress(task));

  for (const task of tasksToCancel) {
    // Cancel upload (if in progress), and remove from store (always).
    uploadManager.cancelFileUpload(task.id, true);
  }

  // Return the number of canceled tasks. For Amplitude event.
  return [tasksToCancel.length, tasksToCancelInProgress.length];
}
