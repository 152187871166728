import { CaptureTreeRevision } from "@custom-types/capture-tree-types";
import {
  CaptureApiClient,
  RegistrationState,
  RevisionType,
} from "@faro-lotv/service-wires";

export const MOCK_CAPTURE_TREE_REVISIONS: CaptureTreeRevision[] = [
  {
    id: "3ac4a8b1-5b0d-459a-3567-08dcabdb3d65",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-24T13:06:25.8159079+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-24T13:06:25.8159079+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "4e7d5b09-5eb6-4549-6970-08dcaa3830dc",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.registered,
    createdByClient: null,
    reportUri: "someUri",
    createdAt: "2024-07-24T09:13:32.3691879+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-24T09:17:10.336987+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "34e9c835-3766-4f37-687c-08dcaa3830dc",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.merged,
    createdByClient: CaptureApiClient.registrationBackend,
    reportUri: "someUri",
    createdAt: "2024-07-22T17:32:29.6363316+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-24T07:01:34.1519557+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "dea429f6-764f-4e81-668a-08dcaa383283",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-23T11:38:34.5510438+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-23T11:38:35.7525587+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "b1072086-3033-4700-68db-08dcaa3830dc",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-23T12:15:35.1815234+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-23T12:15:35.1815234+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "26c0a171-9145-41d7-6896-08dcaa3830dc",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-23T08:57:41.7649619+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-23T08:57:42.9306746+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "5392b745-1eda-4ac6-6881-08dcaa3830dc",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-22T18:15:01.4151386+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-22T18:15:02.5357922+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "0d33977d-4bd7-4469-687f-08dcaa3830dc",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-22T17:57:55.8522536+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-22T17:57:56.8950113+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "0ad98866-8ce9-4d95-6637-08dcaa383283",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-22T13:33:53.0061075+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-22T13:33:53.0061075+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "f3ca25a8-3c87-484b-6861-08dcaa3830dc",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-22T13:33:03.6715155+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-22T13:33:03.6715155+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "8268fb4f-997a-461d-6630-08dcaa383283",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-22T13:32:05.6818461+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-22T13:32:05.6818461+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "9229efd8-4a6b-484c-662f-08dcaa383283",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-22T13:30:49.4464608+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-22T13:30:49.4464608+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "0747f418-9508-4b8d-f7ed-08dca728acfa",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-22T10:17:13.4026721+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-22T10:17:13.4026721+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "b70a1792-87cc-4dab-f7eb-08dca728acfa",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-22T10:10:51.0891782+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-22T10:10:51.0891782+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "34d5cdee-2abe-420c-f7ba-08dca728acfa",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-19T14:49:32.8737133+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-19T14:49:34.5833866+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "aca95809-e8cd-4d17-f7ab-08dca728acfa",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-19T13:00:09.1099992+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-19T13:00:10.4458875+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "4f19347e-4654-4d04-27b6-08dca72e2855",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-19T12:59:25.2572075+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-19T12:59:27.2045258+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "59fd2b0e-61d9-4811-27b5-08dca72e2855",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-19T12:36:31.521434+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-19T12:36:33.0152588+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "5f274b40-e43c-4302-27b2-08dca72e2855",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-19T11:57:23.4055673+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-19T11:57:24.7547506+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "0839e9ea-b903-4169-f79f-08dca728acfa",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-19T10:12:41.1208897+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-19T10:12:42.6211098+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "40de2fe1-0732-4b34-2797-08dca72e2855",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-19T07:08:58.2492147+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-19T07:08:59.6917336+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "c8e30fcc-388a-464f-2769-08dca72e2855",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-18T15:00:36.3883966+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-18T15:00:37.7089772+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "d23a8aed-3c3f-48d8-2767-08dca72e2855",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-18T14:41:26.6713061+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-18T14:41:28.3022421+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "2c068dc3-882d-4ca6-f76f-08dca728acfa",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-18T14:39:13.1624357+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-18T14:39:14.8391121+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "9cd00c4b-1c64-4595-f76e-08dca728acfa",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-18T14:35:35.3839053+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-18T14:35:37.4431646+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "13196001-c45b-4226-2766-08dca72e2855",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-18T14:32:38.2785563+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-18T14:32:39.6395502+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "440756b1-8832-4d1c-f76d-08dca728acfa",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-18T14:26:34.6255482+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-18T14:26:35.9632054+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "0c8d88eb-fc3e-4f30-2764-08dca72e2855",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-18T14:19:43.2181917+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-18T14:19:44.825912+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "e8d9be1b-3e83-4ff8-f765-08dca728acfa",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-18T14:01:45.5312998+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-18T14:01:46.8440295+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "cc2472b9-8c7f-41a4-14c6-08dca6fab038",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-18T08:44:01.894725+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-18T08:44:01.894725+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "17675c58-c06e-4b1d-4fe8-08dca6fc8a2a",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-18T08:43:54.099162+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-18T08:43:54.099162+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "14000b91-757a-42dc-14c2-08dca6fab038",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-18T08:05:30.6564914+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-18T08:06:15.2691284+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "94cefdc0-367d-4e0e-20f2-08dca64b1abc",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.registered,
    createdByClient: null,
    reportUri: "someUri",
    createdAt: "2024-07-17T16:25:57.7688821+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-17T16:29:35.1459301+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "0f2bd158-1c67-4828-4ee3-08dca64ce840",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-17T16:14:56.2744372+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-17T16:14:57.692855+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "02849c90-5ca4-4f34-20f1-08dca64b1abc",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-17T16:13:43.9073782+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-17T16:15:20.7381754+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "c6903efc-2217-45be-4ee0-08dca64ce840",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-17T16:09:31.0098088+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-17T16:11:04.1520235+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "875e70af-5433-4f93-20ce-08dca64b1abc",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-17T13:38:13.9502373+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-17T13:38:15.2609981+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "115119bf-a228-4ecc-20c0-08dca64b1abc",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-17T13:22:20.7098949+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-17T13:22:21.9708492+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "3e7ea953-9a48-4f41-20bd-08dca64b1abc",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-17T13:13:49.2961651+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-17T13:13:50.8453397+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "1b52376e-11d0-4582-4ebd-08dca64ce840",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-17T12:35:37.8142832+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-17T12:35:39.34238+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "8baec7b1-1602-4280-4eba-08dca64ce840",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-17T12:19:51.9283514+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-17T12:20:00.929621+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "dff1c423-a179-4336-20b3-08dca64b1abc",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-17T12:18:06.7510639+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-17T12:18:08.3484839+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "381c3771-22f6-4f0f-11f7-08dca5a77160",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-17T09:17:51.3945541+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-17T09:17:51.3945541+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "bc022972-b5c7-4d6a-38a1-08dca5a059c4",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-17T07:56:30.788355+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-17T07:56:32.1658627+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "372d9112-2903-4262-11e1-08dca5a77160",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-17T07:52:33.1909501+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-17T07:52:36.4896519+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "1ff472ae-06a5-4f10-0a7d-08dca4ab937b",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-16T07:35:01.5983192+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-16T07:35:40.776272+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "397689d6-50e3-4c2b-0a7c-08dca4ab937b",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-16T07:08:37.0681032+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-16T07:09:16.6804208+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "9989fd43-1e33-427e-daca-08dca4a92d11",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-15T12:35:04.8823385+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-15T13:22:05.397711+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "8859f327-bb74-4383-db12-08dca0db0edd",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-12T07:39:04.6803973+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-12T07:41:29.9226242+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "121195da-7183-4d55-daf5-08dca0db0edd",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T11:07:59.3047447+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-12T07:33:37.1532725+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "f7282ad6-d04f-4de3-6a75-08dca0d945db",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T11:07:32.7216497+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T11:07:32.7216497+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "af5bbeb6-68ea-4aca-daf4-08dca0db0edd",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T11:07:14.4910357+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T11:07:14.4910357+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "b2a34e3e-dfe0-42c3-daf3-08dca0db0edd",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:30:18.6160799+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:30:18.6160799+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "7200aa07-9689-4ba2-6a74-08dca0d945db",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:30:09.4180969+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:30:09.4180969+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "1b59b374-49a0-4c52-daf2-08dca0db0edd",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:29:00.6174901+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:29:00.6174901+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "b7c4486d-3531-4679-6a73-08dca0d945db",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:28:52.2637117+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:28:52.2637117+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "6eaf2b68-fafd-4c1c-daf1-08dca0db0edd",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:28:03.582426+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:28:03.582426+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "e9e3ba68-b257-4f5a-daf0-08dca0db0edd",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:27:53.0167686+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:27:53.0167686+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "c7052c34-dd42-4109-6a72-08dca0d945db",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:27:43.9308418+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:27:43.9308418+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "9f1227d4-8529-4644-6a71-08dca0d945db",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:27:29.8907654+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:27:29.8907654+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "84650b5f-4823-4f5d-daef-08dca0db0edd",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:26:26.3830032+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:26:26.3830032+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "c878763a-a34b-4b9a-6a70-08dca0d945db",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:24:40.1900442+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:24:40.1900442+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "5defeff7-63ae-49fa-daee-08dca0db0edd",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:23:55.9064875+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:23:55.9064875+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "b30246df-70fa-4fe9-6a6f-08dca0d945db",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:23:45.4315976+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:23:45.4315976+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "8b7dd2dd-d2df-4be0-daed-08dca0db0edd",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:23:28.6252788+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:23:28.6252788+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "f685b38a-e347-48cb-daec-08dca0db0edd",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:22:41.0294599+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:22:41.0294599+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "700d8a4e-034e-4c3e-6a6e-08dca0d945db",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:22:35.5702539+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:22:35.5702539+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "3e6fd164-fcc7-4439-6a6d-08dca0d945db",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:22:00.5886825+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:22:00.5886825+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "5672436f-fbd6-4fda-daeb-08dca0db0edd",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:21:54.7841048+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:21:54.7841048+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "8068f278-4ad1-4cca-6a6c-08dca0d945db",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:21:16.6522807+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:21:16.6522807+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "5ec5e5a4-c53d-4d92-daea-08dca0db0edd",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:21:06.6341122+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:21:06.6341122+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "c187d71b-d543-4e07-dae9-08dca0db0edd",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:11:03.6606005+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:11:03.6606005+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "af25cedd-4b86-4a06-6a6b-08dca0d945db",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:10:51.750061+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:10:51.750061+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "a773f198-90ef-4e79-6a6a-08dca0d945db",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-11T09:10:05.8241655+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-11T09:10:05.8241655+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "5a8b738a-2643-4fb2-dc57-08dc9ffc1337",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-10T09:04:25.9445297+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-10T09:04:25.9445297+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
];

export const MOCK_OPEN_DRAFT_REVISIONS: CaptureTreeRevision[] = [
  {
    id: "3ac4a8b1-5b0d-459a-3567-08dcabdb0000",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.started,
    revisionType: RevisionType.draft,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-24T13:06:25.8159079+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-24T13:06:25.8159079+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "3ac4a8b1-5b0d-459a-3567-08dcabdb0001",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    revisionType: RevisionType.draft,
    createdByClient: CaptureApiClient.registrationBackend,
    reportUri: null,
    createdAt: "2024-07-24T13:06:25.8159079+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-24T13:06:25.8159079+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "3ac4a8b1-5b0d-459a-3567-08dcabdb0002",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.userModified,
    revisionType: RevisionType.draft,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-24T13:06:25.8159079+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-24T13:06:25.8159079+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "3ac4a8b1-5b0d-459a-3567-08dcabdb0003",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.registered,
    revisionType: RevisionType.draft,
    createdByClient: CaptureApiClient.dashboard,
    reportUri: null,
    createdAt: "2024-07-24T13:06:25.8159079+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-24T13:06:25.8159079+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
];

export const MOCK_MERGED_DRAFT_REVISIONS: CaptureTreeRevision[] = [
  {
    id: "3ac4a8b1-5b0d-459a-3567-08dcabdb0010",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.merged,
    revisionType: RevisionType.draft,
    createdByClient: CaptureApiClient.stream,
    reportUri: null,
    createdAt: "2024-07-24T13:06:25.8159079+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-24T13:06:25.8159079+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
];

export const MOCK_CANCELED_DRAFT_REVISIONS: CaptureTreeRevision[] = [
  {
    id: "3ac4a8b1-5b0d-459a-3567-08dcabdb0020",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.canceled,
    revisionType: RevisionType.draft,
    createdByClient: CaptureApiClient.stream,
    reportUri: null,
    createdAt: "2024-07-24T13:06:25.8159079+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-24T13:06:25.8159079+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
];
